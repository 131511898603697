/* boostrap carousel extra div for captions outside */
(function($) {
    jQuery(document).ready(function(){
        $('.carousel').carousel();
        var caption = $('.carousel-item .carousel-caption');
        $('#row_under_carousel span').html(caption.html());
        caption.css('display','none');
        $(".carousel").on('slide.bs.carousel', function(evt) {
        var caption = $('.carousel-item:nth-child(' + ($(evt.relatedTarget).index()+1) + ') .carousel-caption');
        $('#row_under_carousel span').html(caption.html());
        caption.css('display','none');
        });

        //toogle class for employees
        $('.uncmc-collapse-item').on('show.bs.collapse', function() {
            $(this).toggleClass('collapsed').toggleClass('col-sm-4').toggleClass('col-sm-12');
			$(this).toggleClass('collapsed').toggleClass('col-lg-4').toggleClass('col-lg-12');
            $(this).parent().addClass('active');
         });

        $('.uncmc-collapse-item').on('hide.bs.collapse', function () {
            $(this).toggleClass('collapsed').toggleClass('col-sm-12').toggleClass('col-sm-4');
			$(this).toggleClass('collapsed').toggleClass('col-lg-12').toggleClass('col-lg-4');
            $(this).parent().removeClass('active');
        });

		  //toogle class for governance

		  $('.uncmc-collapse-item').on('show.bs.collapse', function() {
			$('.directors-photo').toggleClass('col-lg-4').toggleClass('col-lg-2');
			$('.directors-content').toggleClass('col-lg-8').toggleClass('col-lg-10');

         });
		 $('.uncmc-collapse-item').on('hide.bs.collapse', function () {
			$('.directors-photo').toggleClass('col-lg-4').toggleClass('col-lg-2');
			$('.directors-content').toggleClass('col-lg-8').toggleClass('col-lg-10');
        });
    });


})(jQuery);